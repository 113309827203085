import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import { URL } from '../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'react-bootstrap'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { IS_PRODUCTION } from '../constants'

const JudgesCard = (props) => {
  const { judge, onEdit, onDelete, loading } = props
  const [showOptions, setShowOptions] = useState(false)

  const menuRef = useRef()

  useOnClickOutside(menuRef, () => {
    setTimeout(() => {
      setShowOptions(false)
    }, 100)
  })


  const toggleOptions = () => {
    setShowOptions(!showOptions)
  }

  console.log('isProduction', typeof IS_PRODUCTION)
  

  return (
    <div className="JudgesCard d-flex align-items-center gap-4">
      {loading && (
        <div className="loading-overlay d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      )}
      <img src={IS_PRODUCTION === 'false' ? `${URL}${judge.image}` : judge.image} alt={judge.name} width={300} height={350} />
      <div className="card-body w-100 d-flex flex-column gap-3 h-100 py-4">
        <h5 className="card-title">{judge.name}</h5>
        <p
          className="card-text overflow-auto"
          style={{ height: '270px', marginTop: '12px' }}
          dangerouslySetInnerHTML={{ __html: judge.description }}
        ></p>
      </div>
      <div className="options">
        <FontAwesomeIcon icon={faEllipsisV} className="options-icon" onClick={toggleOptions} />
        {showOptions && (
          <div className="options-menu" ref={menuRef}>
            <button className="edit-btn text-4 fw-normal d-flex align-items-center gap-2" onClick={onEdit}>
              <FontAwesomeIcon icon={faEdit} className="text-4" style={{ marginBottom: '2px' }} />
              <span>Edit</span>
            </button>
            <button className="delete-btn text-4 fw-normal d-flex align-items-center gap-2" onClick={onDelete}>
              <FontAwesomeIcon icon={faTrash} className="text-4" style={{ marginBottom: '2px' }} />
              <span>Delete</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default JudgesCard

JudgesCard.propTypes = {
  judge: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool
}
