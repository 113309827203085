import { Nav, Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import VideoCard from '../components/VideoCard'
import PrevIcon from '../assets/landing/prevIcon.png'
import NextIcon from '../assets/landing/nextIcon.png'
import CompetitionLogo from '../assets/landing/homeLogo.webp'
import HomeBanner from '../assets/landing/HomeBanner.webp'
import HomeBannerMobile from '../assets/landing/HomeBannerMobile.webp'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useState, createContext, useContext, useEffect, useMemo } from 'react'
import { Navigation, A11y } from 'swiper'
import { getVideos, getVideosDetailsNew, get_selectedWinner } from '../api/videos'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { useQuery } from 'react-query'
// import Leaderboard from '../components/Leaderboard'
import SubmitVideo from '../components/SubmitVideo'
import HowToEnter from '../components/HowToEnter'
import DownloadModal from '../components/DownloadModal'
import CountdownTimer from '../components/Countdown'
import { AppContext } from '../context'
import { Helmet } from 'react-helmet'
import YouTubeVideoModal from '../components/YouTubeVideoModal'
import { useWindowSize } from '@uidotdev/usehooks'
import Prizes from '../components/Prizes'
import MeetOurJudges from '../components/MeetOurJudges'

export const DownloadModalContext = createContext()

const Home = () => {
  const navigate = useNavigate()
  const size = useWindowSize()

  const {
    token,
    unregisteredUserId,
    openVideoModal,
    competitionInfo,
    timelineCompetitions,
    serverInfo
  } = useContext(AppContext)

  const [filter, setFilter] = useState('recommended')
  const [show, setShow] = useState(false)
  const [leaderboardVideos, setLeaderboardVideos] = useState([])
  const [ renderParallaxState, setRenderParallaxState ] = useState({
    a: '',
  })

  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  const [searchParams] = useSearchParams()
  const { isFetching, data, refetch } = useQuery(
    ['videos', filter, unregisteredUserId],
    () =>
      getVideos(
        {
          page: 1,
          per_page: 12,
          competition_id: competitionInfo?.competition?.id,
          hosted_in:
            filter === 'TikTok' ||
              filter === 'Instagram' ||
              filter === 'Youtube'
              ? filter
              : '',
          order_by:
            filter === '-upload_time' ? filter : '',
          recommended: filter === 'recommended' ? filter : '',
          unregistered_user_id: !token ? unregisteredUserId : '',
        },
      ),
    {
      initialData: { total_pages: 1, actual_page: 1, data: [] },
      enabled: !!competitionInfo?.competition?.id,
    }
  )
  // const isCompetitionCloseToEnd = useMemo(() => {
  //   const end_date = competitionInfo?.competition.end_date

  //   if (!end_date) {
  //     return false
  //   }

  //   const competitionEnd = new Date(end_date).getTime()
  //   const today = new Date().getTime()
  //   const sevenDays = 7 * 24 * 60 * 60 * 1000 // día, horas, minutos, segundos, milisegundos
  //   return competitionEnd - today < sevenDays
  // }, [competitionInfo])

  // Force re render parallax when window size changes
  useEffect(() => {
    setRenderParallaxState({...renderParallaxState })
  }, [size])


  const competition_id =
    timelineCompetitions?.currentCompetition?.id ||
    (timelineCompetitions?.lastCompetitions?.length
      && timelineCompetitions.lastCompetitions[0].id)

  const { data: selectedWinnerData } = useQuery(
    ['getSelectedWinner', competition_id],
    () => {
      if (competition_id) {
        return get_selectedWinner({ competition_id })
      }
    },
    {
      initialData: {
        data: {
          admin_description: '',
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
    }
  )
  const [showYoutubeModal, setShowYoutubeModal] = useState(false)

  const handleFilter = (value) => {
    setFilter(value)
    setLeaderboardVideos([])
  }

  const handleOpenVideo = async (video_id) => {
    const { ok, data } = await getVideosDetailsNew(token, video_id, {
      unregistered_user_id: unregisteredUserId,
    })

    if (!ok) console.error(data)
    else {
      openVideoModal({ ...data, refetch })
    }
  }

  const openShowYoutubeModal = () => {
    setShowYoutubeModal(true)
  }

  const closeShowYoutubeModal = () => {
    setShowYoutubeModal(false)
  }

  useEffect(() => {
    const video_id = searchParams.get('video_id')
    if (video_id) handleOpenVideo(video_id)
  }, [unregisteredUserId])

  const videos = useMemo(() => {
    if (filter === '-votes' && leaderboardVideos.length > 0) {
      return leaderboardVideos
    }
    return Array.isArray(data?.data) ? data.data : []
  }, [data, leaderboardVideos, filter])

  // const handleOpenVideoExample = async () => {
  //   openVideoModal({
  //     hosted_in: competitionInfo.competition.video_example.hosted_in,
  //     video_link: competitionInfo.competition.video_example.video_link,
  //     name: competitionInfo.competition.video_example.title,
  //     description: competitionInfo.competition.video_example.description,
  //     user_name: competitionInfo.competition.video_example.participant_name,
  //     isExample: true,
  //   })
  // }
  // const handleOpenVideoWinner = async () => {
  //   openVideoModal({
  //     hosted_in: selectedWinnerData.data.hosted_in,
  //     video_link: selectedWinnerData.data.video_link,
  //     name: selectedWinnerData.data.name,
  //     description: selectedWinnerData.data.description,
  //     user_name: selectedWinnerData.data.user_name,
  //     user_last_name: selectedWinnerData.data.user_last_name,
  //   })
  // }
  return (
    <>
      <Helmet>
        <title>
          Outback Explorer Competition | RFDS (Queensland Section) + Elders
        </title>
      </Helmet>
      <DownloadModalContext.Provider value={{ show, setShow }}>
        <section className="Hero">

          <img className='Hero__Image' src={HomeBanner} alt="bannerBackground" />
          <div
            className='Hero__Container'
          >
            {competitionInfo && (
              <>
                <img className='Hero__Container__Logo' src={CompetitionLogo} alt="Competition logo" />
                {competitionInfo.state === 'finished' ? (
                  !selectedWinnerData.data.error ? (
                    <>
                      <div className='Home__CompetitionClosed'>
                        <div className='d-flex flex-column align-items-center' style={{ gap: '16px' }}>
                          <p className="hero-text-2 text-white">Congratulations to the winner!</p>
                          <p className="hero-text-2 text-white">{selectedWinnerData?.data.user_name} {selectedWinnerData?.data.user_last_name}</p>
                          <p className="Home__CompetitionClosed__Text text-white fw-400 ffam-FreeSans">Thank you to everyone who entered & voted!</p>
                        </div>
                        <Button variant="rs" className="mt-4 mt-md-0" onClick={() => navigate('/all-videos/')}>
                          Watch videos
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className='Home__CompetitionClosed'>
                      <div className='d-flex flex-column align-items-center' style={{ gap: '16px' }}>
                        <p className="hero-text-2 text-white">Competition now closed</p>
                        <p className="Home__CompetitionClosed__Text text-white fw-400 ffam-FreeSans">Thank you to everyone who entered & voted!</p>
                      </div>
                      <Button variant="rs" className="mt-4 mt-md-0" onClick={() => navigate('/all-videos/')}>
                        Watch videos
                      </Button>
                    </div>
                  )
                ) : null}
                {competitionInfo.state === 'nearFuture' && (
                  <>
                    <div className='Home__OpensIn'>
                      <p className="mb-0 hero-text-2">Competition opens in</p>
                      <CountdownTimer targetDate={competitionInfo.competition.start_date} />
                    </div>
                  </>
                )}
                {competitionInfo.state === 'ongoing' && (
                  <>
                    <div className='Home__LiveCompetition'>
                      <p className='hero-text-2 mb-0 text-white'>Share a video of Queensland for a chance to win $10,000</p>
                      <p className='Home__LiveCompetition__Subtitle ffam-FreeSans'>+ Many more amazing prizes!</p>
                    </div>
                    <div className="Home__LiveCompetition__BtnContainer ">
                      <Button
                        variant="rs-secondary"
                        onClick={() => navigate('/#how-to-enter')}
                        className='Home__LiveCompetition__BTN'
                      >
                        How to enter
                      </Button>
                      <Button
                        variant="rs"
                        href="#enter-competition"
                        className='Home__LiveCompetition__BTN'
                      >
                        Submit video
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </section>

        {competitionInfo && competitionInfo.state !== 'nearFuture' && (
          <section className='Home__AllVideos-Section'>
            <Container>
              <Nav
                variant="tabs"
                defaultActiveKey="recommended"
                className="mb-4 d-flex gap-3 AllVideos"
                onSelect={handleFilter}
              >
                <Nav.Item className="w-max-cont">
                  <Link to={`/all-videos/?competition_id=${competitionInfo?.competition?.id}`}>
                    <Button className="w-max-cont" variant={'rs'}>
                      See all videos
                    </Button>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="recommended">
                    Recommended
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="-upload_time">
                    Latest
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="-votes">
                    Most popular
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="TikTok">
                    TikTok
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="Instagram">
                    Instagram
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="w-max-cont" eventKey="Youtube">
                    YouTube
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {!data.error && Array.isArray(videos) && videos.length > 0 ? (
                <div className="slider-container">
                  <Swiper
                    modules={[Navigation, A11y]}
                    navigation={{
                      nextEl: '.review-swiper-button-next',
                      prevEl: '.review-swiper-button-prev',
                    }}
                    spaceBetween={20}
                    slidesPerView={1}
                    onSlideChange={() => { }}
                    onSwiper={() => { }}
                    breakpoints={{
                      576: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {videos?.map((element) => (
                      <SwiperSlide key={element.id}>
                        <VideoCard
                          id={element.id}
                          socialNet={element.hosted_in}
                          likes={element.votes}
                          name={element.name}
                          videoName={element.name}
                          videoSrc={element.video_link}
                          firstName={element.user_name}
                          lastName={element.user_last_name}
                          competitionYear={element.competition_name}
                          slug={element.slug}
                          description={element.description}
                          staffPick={element.is_staff_pick}
                          new={element.new}
                          image={element.thumbnail}
                          voted={element.already_voted}
                          video={element}
                          refetch={refetch}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <img src={PrevIcon} className="review-swiper-button-prev" />
                  <img src={NextIcon} className="review-swiper-button-next" />
                </div>
              ) : isFetching ? (
                <div className="text-center" style={{ padding: '187.625px 0' }}>
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <></>
              )}
            </Container>
          </section>
        )}

        {(competitionInfo && competitionInfo.state === 'ongoing' ) && <HowToEnter openVideo={openShowYoutubeModal} />}
        {(competitionInfo && competitionInfo.state === 'nearFuture') && <HowToEnter className='my-5'  openVideo={openShowYoutubeModal} />}

        <Prizes />

        {(competitionInfo && competitionInfo.state === 'ongoing'  || competitionInfo && competitionInfo.state === 'nearFuture') && <SubmitVideo />}
        {(competitionInfo && competitionInfo.state === 'ongoing'  || competitionInfo && competitionInfo.state === 'nearFuture') && serverInfo.is_judges_on && <MeetOurJudges />}


        <DownloadModal />

        <YouTubeVideoModal
          isVisible={showYoutubeModal}
          handleClose={closeShowYoutubeModal}
          videoLinkId="2UKo7QLMx-U"
        />

      </DownloadModalContext.Provider>
    </>
  )
}

export default Home
